<template>
  <v-app>
    <v-container class="fill-height">
      <v-row justify="center">
        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
          <v-img
            height="250"
            :src="require('@/assets/error_404.png')"
          ></v-img>

          <v-card-title>Pagina no encontrada</v-card-title>

          <v-card-text>
            Por favor comunicate con el equipo de soporte
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-actions>
            <v-btn block color="primary" @click="toBack()">
              Regresar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>

    <footer-component />
  </v-app>
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";
export default {
  name: "NoFound",
  components: {
    "footer-component": FooterComponent,
  },
  data: () => ({
    loading: false,
    selection: 1,
  }),
  methods: {
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>
